import React, {useState, useEffect} from "react";
import axios from 'axios';
import { useEventSettings } from "./contexts/eventsettings";
import { useMyRegistration } from "./contexts/myregistration.js";


import { FormCard, FormLabel, SaveButton, SaveSpinnerContainer,StyledInputGroup } from "./styled-components/Forms.js";

import Switch from './custom-widgets/Switch.js'; 
import Checkbox from './custom-widgets/Checkbox.js'; 

import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'

import './custom-widgets/Switch.css'; 


function BasicInfo(props) {
	const { myRegistration, setMyRegistration } = useMyRegistration(); 
  const { eventSettings } = useEventSettings();
	const [isSavingToServer, setIsSavingToServer] = useState(false);
	const [isEdited, setIsEdited] = useState(false);
	const [genderOptions, setGenderOptions] = useState({});
	const [tshirtsizeOptions, setTshirtsizeOptions] = useState({});
	const [dietaryOptions, setDietaryOptions] = useState({});
	
	const [sceneName, setSceneName] = useState('');
	const [hometownCity, setHometownCity] = useState('');
	const [hometownState, setHometownState] = useState('');
	const [role, setRole] = useState('');
	const [pronouns, setPronouns] = useState('');
	const [hometownZip, setHometownZip] = useState('');
	const [hometownStateOptions, setHometownStateOptions] = useState({}); 
	const [pronounsOptions, setPronounsOptions] = useState({}); 
	const [roleOptions, setRoleOptions] = useState({}); 
	const [showHometown, setShowHometown] = useState(false); 
	const [showAge, setShowAge] = useState(false); 
	
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [gender, setGender] = useState('');
	const [birthdayMonth, setBirthdayMonth] = useState('');
	const [birthdayDate, setBirthdayDate] = useState('');
	const [birthdayYear, setBirthdayYear] = useState('');
	const [tShirtSize, setTShirtSize] = useState('');
	const [smsOk, setSmsOk] = useState(true);
	const [erName, setErName] = useState('');
	const [erPhone, setErPhone] = useState('');
	const [foodAllergies, setFoodAllergies] = useState('');
	const [erAttending, setErAttending] = useState(false);
	const [reducedMobility, setReducedMobility] = useState(false);
	const [adaRequired, setAdaRequired] = useState(false);
	const [aslRequired, setAslRequired] = useState(false);
	const [medicalAllergies, setMedicalAllergies] = useState('');
	const [emergencyCareInfo, setEmergencyCareInfo] = useState('');
	const [careNotes, setCareNotes] = useState('');
	const [dietaryCeliac, setDietaryCeliac] = useState(false);
	const [dietaryGlutenFree, setDietaryGlutenFree] = useState(false);
	const [dietaryVegetarian, setDietaryVegetarian] = useState(false);
	const [dietaryVegan, setDietaryVegan] = useState(false);
	const [dietaryLactose, setDietaryLactose] = useState(false);
	const [dietaryNuts, setDietaryNuts] = useState(false);
	const [dietaryLowCarb, setDietaryLowCarb] = useState(false);
	
	useEffect(() => {
	axios.get(process.env.REACT_APP_API_HOST+"/events/"+eventSettings.key+"/fieldoptions")
				.then(result => {
								if (result.status === 200) {
									setGenderOptions(result.data["guest.gender"]); 
									setDietaryOptions(result.data["care.dietary"]); 
									setTshirtsizeOptions(result.data["guest.tshirtsize"]); 
									setHometownStateOptions(result.data["guest.homestate"]); 
									setRoleOptions(result.data["guest.role"]); 
									setPronounsOptions(result.data["guest.pronouns"]); 
								} else {
								}
							}).catch(e => {
							});
	},[eventSettings.key]); 
	
	var today = new Date(); 
	
	const birthdayDayOptions = []; 
	const birthdayYearOptions = []; 
	for(var d=1; d<32; d++){ 
		birthdayDayOptions.push(<option value={((d<10)?'0':'')+d} key={d}>{d}</option>); 
	} 
	for(var i=(parseInt(today.getFullYear())-17); i>1940; i--){ 
		birthdayYearOptions.push(<option value={i} key={i}>{i}</option>); 
	} 
	
	function handleFieldChange(id,val){ 
		setIsEdited(true); 
		if(id==='sceneName') setSceneName(val); 
		if(id==='profile_city') setHometownCity(val); 
		if(id==='profile_state') setHometownState(val); 
		if(id==='profile_zip') setHometownZip(val); 
		if(id==='profile_pronouns') setPronouns(val); 
		if(id==='profile_role') setRole(val); 
		
		if(id==='firstname') setFirstName(val); 
		if(id==='lastname') setLastName(val); 
// 		if(id==='email') setEmail(val); 
		if(id==='phone') setPhone(val); 
		if(id==='gender') setGender(val); 
		if(id==='er_name') setErName(val); 
		if(id==='er_phone') setErPhone(val); 
		if(id==='tshirtsize') setTShirtSize(val); 
		if(id==='foodAllergies') setFoodAllergies(val); 
		if(id==='medicalAllergies') setMedicalAllergies(val); 
		if(id==='emergencyCareInfo') setEmergencyCareInfo(val); 
		if(id==='careNotes') setCareNotes(val); 
		if(id==='birthday_month') setBirthdayMonth(val); 
		if(id==='birthday_day') setBirthdayDate(val); 
		if(id==='birthday_year') setBirthdayYear(val); 
	} 
	
	function savePublicProfile(){ 
		
		myRegistration.eventId = eventSettings.key; 
		myRegistration.sourceCode = 'pitmwebsite'; 
		
		myRegistration.profile = {}; 
		myRegistration.profile.hometown = {}; 
		myRegistration.guest = {}; 
		myRegistration.guest.phone = {}; 
		myRegistration.careAndFeeding = {}; 
		myRegistration.careAndFeeding.emergencyContact = {}; 
		
		if(sceneName) myRegistration.profile.sceneName = sceneName; 
// 		myRegistration.profile.preferences.showHometown = showHometown; 
// 		myRegistration.profile.preferences.showAge = showAge; 
// 		myRegistration.profile.preferences.allowEmail = allowMessages; 
		myRegistration.profile.hometown.city = hometownCity; 
		myRegistration.profile.hometown.state = hometownState; 
		myRegistration.profile.hometown.zip = hometownZip; 
		myRegistration.guest.role = role; 
		myRegistration.guest.pronouns = pronouns; 
		
		if(firstName!==null) myRegistration.guest.firstName = firstName; 
		if(lastName!==null) myRegistration.guest.lastName = lastName; 
		if(email!==null) myRegistration.guest.website = email; 
		myRegistration.guest.birthdate = birthdayYear+'-'+birthdayMonth+'-'+birthdayDate; 
		if(phone!==null) myRegistration.guest.phone.number = phone; 
 		myRegistration.guest.phone.smsOK = true;//smsOk; 
		if(gender!==null) myRegistration.guest.gender = gender; 
 		myRegistration.guest.tShirtSize = tShirtSize; 
 		myRegistration.careAndFeeding.emergencyContact.isAttendingEvent = erAttending; 
		if(erName!==null) myRegistration.careAndFeeding.emergencyContact.name = erName; 
		if(erPhone!==null) myRegistration.careAndFeeding.emergencyContact.phone = erPhone; 
 		myRegistration.careAndFeeding.reducedMobility = reducedMobility; 
 		myRegistration.careAndFeeding.requiresADA = adaRequired; 
 		myRegistration.careAndFeeding.requiresASL = aslRequired; 
		if(foodAllergies!==null) myRegistration.careAndFeeding.foodAllergies = foodAllergies; 
		if(medicalAllergies!==null) myRegistration.careAndFeeding.medicalAllergies = medicalAllergies; 
		if(emergencyCareInfo!==null) myRegistration.careAndFeeding.emergencyMedicalInfo = emergencyCareInfo; 
		if(careNotes!==null) myRegistration.careAndFeeding.notes = careNotes; 
		
		myRegistration.careAndFeeding.dietaryConcerns = [];
		if(dietaryCeliac===true) myRegistration.careAndFeeding.dietaryConcerns.push('celiac');
		if(dietaryGlutenFree===true) myRegistration.careAndFeeding.dietaryConcerns.push('glutenfree');
		if(dietaryVegetarian===true) myRegistration.careAndFeeding.dietaryConcerns.push('vegetarian');
		if(dietaryVegan===true) myRegistration.careAndFeeding.dietaryConcerns.push('vegan');
		if(dietaryLactose===true) myRegistration.careAndFeeding.dietaryConcerns.push('lactose');
		if(dietaryNuts===true) myRegistration.careAndFeeding.dietaryConcerns.push('nuts');
		if(dietaryLowCarb===true) myRegistration.careAndFeeding.dietaryConcerns.push('lowcarb');
		
		setIsSavingToServer(true); 
		setMyRegistration(myRegistration); 
	} 
	
	
	
	
    return (<>
			<Container className="form-cards">
				<Row>
					<Col md={12} lg={6}>
						
						<FormCard id="" className="gold">
							<Card.Header as="h5">All About You</Card.Header>
							<Card.Body>
								<Form.Row>
									<Form.Group as={Col} sm={12}>
										<FormLabel.Primary>Scene Name</FormLabel.Primary>
										<Form.Control placeholder="" id="sceneName" value={sceneName} onChange={e => { handleFieldChange(e.target.id, e.target.value); }}/>
										<Form.Text id="scenenameHelpBlock" muted>This appears on your profile and event badge.</Form.Text>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col}  sm={6}>
										<FormLabel.Primary>Role Identity</FormLabel.Primary>
										<Form.Control id="profile_role" as="select" value={role} onChange={e => { handleFieldChange(e.target.id, e.target.value); }} >
												<option></option>
												{
													Object.keys(roleOptions).map((value) => <option key={value} value={value}>{roleOptions[value]}</option> )
												}
										</Form.Control>
									</Form.Group>
									<Form.Group as={Col}  sm={6}>
										<FormLabel.Primary>Pronouns</FormLabel.Primary>
										<Form.Control id="profile_pronouns" as="select" value={pronouns} onChange={e => { handleFieldChange(e.target.id, e.target.value); }} >
												<option></option>
												{
													Object.keys(pronounsOptions).map((value) => <option key={value} value={value}>{pronounsOptions[value]}</option> )
												}
										</Form.Control>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<FormLabel.Primary as={Col} sm={12}>Hometown</FormLabel.Primary>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col}  sm={5}>
										<FormLabel.Secondary>City</FormLabel.Secondary>
										<Form.Control id="profile_city" value={hometownCity} onChange={e => { handleFieldChange(e.target.id, e.target.value); }}/>
									</Form.Group>
									<Form.Group as={Col}  sm={3}>
										<FormLabel.Secondary>State/Prov</FormLabel.Secondary>
										<Form.Control id="profile_state" as="select" value={hometownState} onChange={e => { handleFieldChange(e.target.id, e.target.value); }} >
											<option></option>
											{
												Object.keys(hometownStateOptions).map((value) => <option key={value}>{value}</option> )
											}
										</Form.Control>
									</Form.Group>
									<Form.Group as={Col} sm={4}>
										<FormLabel.Secondary>Zip/Postcode</FormLabel.Secondary>
										<Form.Control id="profile_zip" value={hometownZip} onChange={e => { handleFieldChange(e.target.id, e.target.value); }}/>
									</Form.Group>
								</Form.Row>
							</Card.Body>
						</FormCard>
						
						
						<FormCard id="" className="blue">
							<Card.Header as="h5">Legal Info & Contact Info</Card.Header>
							<Card.Body>
								<Form.Row>
									<FormLabel.Primary as={Col} sm={12}>Name (As It Appears On Your ID)</FormLabel.Primary>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col}  sm={6}>
										<FormLabel.Secondary>First Name</FormLabel.Secondary>
										<Form.Control id="firstname" value={firstName} onChange={e => { handleFieldChange(e.target.id, e.target.value); }}/>
									</Form.Group>
									<Form.Group as={Col} sm={6}>
										<FormLabel.Secondary>Last Name</FormLabel.Secondary>
										<Form.Control id="lastname" value={lastName} onChange={e => { handleFieldChange(e.target.id, e.target.value); }}/>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<FormLabel.Primary as={Col} sm={12}>Birthdate</FormLabel.Primary>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col}  sm={5}>
										<FormLabel.Secondary>Month</FormLabel.Secondary>
										<Form.Control id="birthday_month" as="select" value={birthdayMonth} onChange={e => { handleFieldChange(e.target.id, e.target.value); }} >
											<option></option>
											<option value="01">January</option>
											<option value="02">February</option>
											<option value="03">March</option>
											<option value="04">April</option>
											<option value="05">May</option>
											<option value="06">June</option>
											<option value="07">July</option>
											<option value="08">August</option>
											<option value="09">September</option>
											<option value="10">October</option>
											<option value="11">November</option>
											<option value="12">December</option>
										</Form.Control>
									</Form.Group>
									<Form.Group as={Col} sm={3}>
										<FormLabel.Secondary>Day</FormLabel.Secondary>
										<Form.Control id="birthday_day" as="select" value={birthdayDate} onChange={e => { handleFieldChange(e.target.id, e.target.value); }} >
											<option></option>
											{birthdayDayOptions}
										</Form.Control>									
									</Form.Group>
									<Form.Group as={Col} sm={4}>
										<FormLabel.Secondary>Year</FormLabel.Secondary>
										<Form.Control id="birthday_year" as="select" value={birthdayYear} onChange={e => { handleFieldChange(e.target.id, e.target.value); }} >
											<option></option>
											{birthdayYearOptions}
										</Form.Control>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col} sm={12}>
										<FormLabel.Primary>Mobile Phone Number</FormLabel.Primary>
										<Form.Control placeholder="" id="phone" value={phone} onChange={e => { handleFieldChange(e.target.id, e.target.value); }}/>
									</Form.Group>
								</Form.Row>
								
								<Form.Row>
									<Form.Group as={Col} sm={12}>
										<FormLabel.Primary>Gender Identity</FormLabel.Primary>
										<Form.Control id="gender" as="select" value={gender} onChange={e => { handleFieldChange(e.target.id, e.target.value); }} >
											<option></option>
											{
												Object.keys(genderOptions).map((value) => <option key={value} value={value}>{genderOptions[value]}</option> )
											}
										</Form.Control>
									</Form.Group>
								</Form.Row>
							</Card.Body>
						</FormCard>
																											 
						<FormCard id="" className="red">
							<Card.Header as="h5">Emergency Contact</Card.Header>
							<Card.Body>
								<Form.Row>
									<Form.Group as={Col}  sm={6}>
										<FormLabel.Secondary>Name</FormLabel.Secondary>
										<Form.Control id="er_name" value={erName} onChange={e => { handleFieldChange(e.target.id, e.target.value); }}/>
									</Form.Group>
									<Form.Group as={Col} sm={6}>
										<FormLabel.Secondary>Phone Number</FormLabel.Secondary>
										<Form.Control id="er_phone" value={erPhone} onChange={e => { handleFieldChange(e.target.id, e.target.value); }}/>
									</Form.Group>
								</Form.Row>
									<Form.Group as={Row} className="switch-row">
										<Switch isOn={erAttending} onColor="#0d9948" handleToggle={() => {setErAttending(!erAttending); setIsEdited(true);  }} id="er_attending" />
										<Form.Label htmlFor="er_attending">
											My emergency contact is attending PITM
										</Form.Label>
									</Form.Group>

							</Card.Body>
						</FormCard>


					</Col>


					<Col md={12} lg={6}>
																											 
						<FormCard id="" className="green">
							<Card.Header as="h5">Care and Feeding</Card.Header>
							<Card.Body>
								<Container>
								<Form.Row>
									<Form.Group as={Col} sm={12}>
										<FormLabel.Primary>T-Shirt Size</FormLabel.Primary>
										<Form.Control id="tshirtsize" as="select" value={tShirtSize} onChange={e => { handleFieldChange(e.target.id, e.target.value); }} >
											<option></option>
											{
												Object.keys(tshirtsizeOptions).map((value) => <option key={value} value={value}>{tshirtsizeOptions[value]}</option> )
											}
										</Form.Control>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<FormLabel.Primary as={Col} sm={12}>Dietary Restrictions</FormLabel.Primary>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col}> 
										<Checkbox checked={dietaryCeliac} label={dietaryOptions.celiac} id="dietary_celiac" key="celiac" onCheck={()=>{ setDietaryCeliac(!dietaryCeliac); setIsEdited(true); }}/> 
										<Checkbox checked={dietaryGlutenFree} label={dietaryOptions.glutenfree} id="dietary_glutenfree" key="gf"  onCheck={()=>{ setDietaryGlutenFree(!dietaryGlutenFree); setIsEdited(true) }}/> 
										<Checkbox checked={dietaryVegetarian} label={dietaryOptions.vegetarian} id="dietary_vegetarian" key="vegetarian" onCheck={()=>{ setDietaryVegetarian(!dietaryVegetarian); setIsEdited(true) }}/> 
										<Checkbox checked={dietaryVegan} label={dietaryOptions.vegan} id="dietary_vegan" key="vegan" onCheck={()=>{ setDietaryVegan(!dietaryVegan); setIsEdited(true) }}/> 
										<Checkbox checked={dietaryLactose} label={dietaryOptions.lactose} id="dietary_lactose" key="lactose" onCheck={()=>{ setDietaryLactose(!dietaryLactose); setIsEdited(true) }}/> 
										<Checkbox checked={dietaryNuts} label={dietaryOptions.nuts} id="dietary_nuts" key="nuts" onCheck={()=>{ setDietaryNuts(!dietaryNuts); setIsEdited(true) }}/> 
										<Checkbox checked={dietaryLowCarb} label={dietaryOptions.lowcarb} id="dietary_lowcarb" key="lowcarb" onCheck={()=>{ setDietaryLowCarb(!dietaryLowCarb); setIsEdited(true) }}/> 

									</Form.Group>
								</Form.Row>
								<Form.Row>
									<FormLabel.Primary as={Col} sm={12}>Accommodations</FormLabel.Primary>
								</Form.Row>

									<Form.Group as={Row} className="switch-row">
										<Switch isOn={reducedMobility} onColor="#0d9948" handleToggle={() => {setReducedMobility(!reducedMobility); setIsEdited(true);  }} id="reducedMobility" />
										<Form.Label htmlFor="reducedMobility">I have reduced mobility but do not require ramps</Form.Label>
									</Form.Group>

									<Form.Group as={Row} className="switch-row">
										<Switch isOn={adaRequired} onColor="#0d9948" handleToggle={() => {setAdaRequired(!adaRequired); setIsEdited(true);  }} id="adaRequired" />
										<Form.Label htmlFor="adaRequired">I require ramps or other ADA accommodations</Form.Label>
									</Form.Group>

									<Form.Group as={Row} className="switch-row">
										<Switch isOn={aslRequired} onColor="#0d9948" handleToggle={() => {setAslRequired(!aslRequired); setIsEdited(true);  }} id="aslRequired" />
										<Form.Label htmlFor="aslRequired">I need an ASL interpreter</Form.Label>
									</Form.Group>

								<Form.Row>
									<Form.Group as={Col}>
										<FormLabel.Primary>Food Allergies</FormLabel.Primary>
										<Form.Control placeholder="" id="foodAllergies" value={foodAllergies} onChange={e => { handleFieldChange(e.target.id, e.target.value); }}/>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col}>
										<FormLabel.Primary>Medical Allergies</FormLabel.Primary>
										<Form.Control placeholder="" id="medicalAllergies" value={medicalAllergies} onChange={e => { handleFieldChange(e.target.id, e.target.value); }}/>
										<Form.Text id="foodallergieshelp" muted>Are there any medicines you cannot have?</Form.Text>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col}>
										<FormLabel.Primary>Emergency Care Info</FormLabel.Primary>
										<Form.Control placeholder="" id="emergencyCareInfo" value={emergencyCareInfo} onChange={e => { handleFieldChange(e.target.id, e.target.value); }}/>
										<Form.Text id="medallergieshelp" muted>What do first responders need to know?</Form.Text>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col}>
										<FormLabel.Primary>Other Notes</FormLabel.Primary>
										<Form.Control placeholder="" id="careNotes" value={careNotes} onChange={e => { handleFieldChange(e.target.id, e.target.value); }}/>
										<Form.Text id="othernoteshelp" muted>Any other concerns or needs you have.</Form.Text>
									</Form.Group>
								</Form.Row>

								</Container>
							</Card.Body>
						</FormCard>
						
					</Col>
				</Row>


				<Row>
					<Col>
						{isSavingToServer ?  
						 <SaveSpinnerContainer>
							<Spinner animation="grow" variant="primary" />&nbsp;
							<Spinner animation="grow" variant="secondary" />&nbsp;
							<Spinner animation="grow" variant="success" />&nbsp;
							<Spinner animation="grow" variant="danger" />&nbsp;
							<Spinner animation="grow" variant="warning" />&nbsp;
							<Spinner animation="grow" variant="info" />
						 </SaveSpinnerContainer>
							:
							<SaveButton disabled={ (isEdited)?false:true } value="Continue" onClick={e => { savePublicProfile(); }}  />
 						}
					</Col>
				</Row>
			</Container>
</>
    );
}
 
export default BasicInfo;