import React, {useState, useEffect} from "react";
import axios from 'axios';
import { useEventSettings } from "./contexts/eventsettings";
import { useMyRegistration } from "./contexts/myregistration.js";


import { FormCard, FormLabel, SubmitButton, SaveSpinnerContainer } from "./styled-components/Forms.js";

import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'

import './custom-widgets/Switch.css'; 


function TermsAndConditions(props) {
	const { myRegistration, setMyRegistration } = useMyRegistration(); 
  const { eventSettings } = useEventSettings();
  const [isSavingRegistration,setIsSavingRegistration] = useState(false);

	const successCallback = props.onAgreement; 
	useEffect(() => {
  window.scrollTo(0, 0)
}, [])
	
	function saveTermsAgreement(){ 
		setIsSavingRegistration(true); 
		successCallback(); 
		
	} 
	
    return (<>
			<Container className="form-cards">
				<Row>
					<Col md={12}>
						<FormCard id="" className="red">
							<Card.Header as="h5">Membership Agreement</Card.Header>
							<Card.Body>
								<Row>
								<Col md={12}>
									<Form.Row>
<p>PITM is a private membership-only organization not open to the public. All guests and volunteers must be participant members of Puppies in the Mountains, Inc., (PITM) a Colorado nonprofit organization. By registering for the 2023 event, you are purchasing a participant membership in PITM, Inc.; upon signature below you will be a duly sworn member and agree to abide by the rules at organization events. Your membership is active from today until September 30, 2024. Membership may not be transferred to another person. In order to change membership, the original membership must be cancelled via the cancellation policy, and a new membership in a different name must be purchased. </p>
<p>PITM is governed by a Board of Directors, which sets the rules and expectations for the event and its participating members. All members and event participants must adhere to the rules and instructions from staff, event coordinators, and volunteers. </p>
	<h4>Venue Rules and Staff</h4>
<p>PITM and its members are guests of La Foret Conference and Retreat Center ("venue"). The venue has taken a chance on us, and PITM is the first nontraditional/fetish event to take place on these grounds. You agree to respect the venue's property including buildings, furniture, and grounds. Clean up after yourself. Report damage to an Event Director immediately. You agree to adhere to all rules imposed by the venue.  </p>
<p>Venue staff will be present on the property during the event. The venue is a welcoming and affirming organization, and welcomes all guests regardless of gender identity and sexual orientation. Even so, be friendly and nonconfrontational at all times. Respect the dress code, respect the personal boundaries of staff, and be good representatives of our community and PITM will be able to return in future years.  </p>
<p>The venue is not gated and public visitors may arrive during the day. Certain buildings and areas will be closed off for our group. During the day, street clothes are required across the central field, in the dining hall, between the dining hall and Ponderosa Hall, and north of Ponderosa Lodge; please hand-carry pup gear. Additional daytime areas may be put in place depending on venue activity or presence of nonevent participants.  </p>
<p>After dusk, pup gear may be worn outside in all areas south of the Ponderosa Lodge. While outside, please keep female nipples, groin, genitals, and buttocks covered. </p>
	<h4>Privacy and Respect for All Members</h4>
<p>All are welcome at Puppies in the Mountains regardless of age, racial identity, gender identity, physical capability or disability, sexual orientation, community identity. You agree to respect all members, event directors, and volunteers. </p>
<p>Members may share personal details and experience before, during, or after the event. You must respect the privacy of all members, and agree not to share any personal information you learn about any other member including their legal name, home town, sexual preferences, community identity, personal experiences or stories, or actions taken during the event. </p>
										<p>Members must be respectful toward each other. Debate of politics or political subjects is not allowed on any of the tools, social media, chat rooms, or other communication channels provided or managed by PITM, including but not limited to the My Experience app or Telegram channels. Discussion of controversial topics including but not limited to COVID-19, gun rights, marijuana legalization, illicit drugs, events in the Middle East, events pertaining to Russia and Eastern Europe, political party affilation, etc, must remain respectful and may be curtailed or ended by the Board of Directors at any time without appeal. Anyone who repeatedly violates this policy may be removed from the social media channel temporarily or permanently, or have their membership cancelled, at the sole discretion of the Board of Directors.</p>
	<h4>Selling</h4>
<p>Sales of any product, item or service is limited to authorized vendors. Private sales from cabins, in common areas, or vehicles are prohibited. </p>
<h4>Cameras, Photography, &amp; Social Media</h4>
<p>Members are allowed to take photos using their personal devices during the event. If you do not wish your photo taken, you will be provided a wristband. <i>Everyone</i> <b>must</b> be mindful of&mdash;and not take photos of&mdash;anyone wearing a wristband. Ensure you have the permission of people before taking their photo. Be mindful of who is in the background.</p>
<p>Do not post wide-angle photos, group photos, or pictures of the venue on social media. Only post photos of your immediate pack/family/friends in close-up or tightly framed photos. Do not tag the venue or publicly post its name in your photos on social media.</p>
	<h4>Alcohol, Smoking, Drugs, and Weapons</h4>
<p>Members may bring alcoholic beverages. Only members aged 21 and over as of October 27, 2023 may bring or consume alcohol. No one under the age of 21 may consume or possess alcoholic beverages. No one age 21 and over may share alcohol with anyone underage, at any point or in any location at the event, including in the private cabins or rooms. At no point may alcohol be sold, bartered, or traded. Members are expected to maintain civil behavior and regulate their consumption. Any member who appears intoxicated may be asked to leave the room. Anyone who violates the law in relation to alcohol will be told to leave the event and their membership revoked. </p>
<p>Tobacco smoking is permitted only in designated outdoor areas, and smokers must properly dispose of their trash. </p>
<p>No illegal drugs are permitted at the event and anyone who possesses, uses, or appears to be under the influence of drugs will have their membership immediately cancelled and told to leave the event. Under Colorado Law the venue is considered a public space, and the law prohibits marijuana consumption there. At no point may marijuana products be sold, bartered, or traded.Weapons of any kind are prohibited by the venue and by PITM.</p>
										<h4>COVID-19 Vaccination</h4>
										<p>The health and safety of our members is very important to us. All members attending Puppies in the Mountains 2023 must have at least one dose of an FDA-approved COVID-19 vaccine by October 27, 2023. You will be required to upload a copy of your COVID-19 vaccination card to the My Experience app before the event. If you have a medical reason for not getting vaccinated, please provide a letter from your doctor stating this fact (the specifics are not necessary, only a doctor's note that you <i>have</i> a medical reason will suffice).</p>
<h4>Play Activity and Consent</h4>
<p>PITM is an adult-oriented fetish community event, where adult language, situations, and nudity may be present. Activities may include physically strenuous play during which members are expected to self-monitor for exhaustion, dehydration, hunger, physical limitations, and any other situation that may lead to adverse health conditions or injury. There is no staffed medical or emergency facility on site. </p>
<p>PITM is not a sexual event. None of the scheduled activities will have an opportunity for any sexual activity. Keep public displays of affection tasteful and respectful. Note that while the venue feels very remote, there may be hikers, neighbors, staff, passers-by anywhere on the trails—please keep your activities decent, tasteful, and respectful in these public spaces. We cannot regulate the private activity of consenting adults in private spaces; members may accidentally encounter such activity but all are expected to preserve their own privacy and that of others. Regardless of your personal experience, skill, or consent, at no time is sexual activity permitted that has a reasonable possibility of injury or death including but not limited to exhibitionism, breath play, asphyxiation, suspension, bloodletting, or cutting. </p>
<p>Regardless of play style, experience, existing relationships, prior consent, or mere presence in the room, do not assume that someone else consents to any given activity including touching, petting, hitting, disrobing, or sex. Request consent prior to sex or sexual activity. If anyone tells you to stop performing an activity on or with them, you must cease immediately. Failure to respect the boundaries and consent of any other member will result in immediate termination of your membership and ejection from the event. </p>
<p>You acknowledge that PITM is an adult-oriented event where adult language, situations and possible partial nudity may be present, and you agree you will not be offended by the presence of such adult themes, language and behaviors. </p>
	<h4>Release of Liability</h4>
<p>In consideration of their services, I hereby voluntarily release, forever discharge, and agree to indemnify and hold harmless Puppies in the Mountains, Inc., La Foret Conference and Retreat Center, their owners, agents, officers, volunteers, boards of directors, staff, and all other persons acting in any capacity on their behalf (hereinafter collectively referred to as “PITM/LFCRC”), on behalf of myself, my children, my parents, my heirs, assigns, personal representative and estate, from any and all claims, demands, or causes of action, which are in any way connected with my voluntary participation in any activity at the event, or my use of any of the equipment provided by PITM/LFCRC. </p>

<ol>
	<li>I understand that the phrase “the event” as used in this Release includes all activities that are a part of and associated with Puppies in the Mountains.</li>
	<li>I assume and accept full responsibility for myself for any bodily injury, death, or loss of personal property that might occur while I am at the event and I expressly agree and promise to accept and assume all the risks existing in any activity I undertake while at the event. My participation in any activity at the event is purely voluntary, and I elect to participate in spite of the potential risks. I understand that PITM/LFCRC is not responsible for my personal safety while I am at the event.</li>
	<li>In consideration for the right to participate in the event, to participate in consensual fetish and/or sexual activities at the event, and in any other activities in which I may participate while at the event, I hereby certify that I am in good physical, mental, emotional, and psychological health with no defects, illnesses or conditions that might be injurious to me or to others, and that I am able to physically and emotionally handle the associated risks inherent in consensual bondage and/or sadomasochistic activities and any conditions that may be associated with such activities or with my observation of these activities, or any other activities in which I may participate while at the event.</li>
	<li>I acknowledge that activities that I may participate in at the event entail both known and unanticipated risks. I understand that such risks cannot be completely eliminated without jeopardizing the essential qualities of the activities. I acknowledge that these risks could result in physical or emotional injury, paralysis, death, or damage to myself, to property, or to third parties, and include: scratches, cuts, sprains, strains, bruises, contusions, lacerations, scalding and burning, breaks and fractures, and severe contortion of the human body. I further acknowledge that the foregoing list of possible injuries and injurious acts is neither complete nor exclusive.</li>
	<li>I understand that both the event and the venue has its own rules and guidelines. I agree to read these rules and guidelines fully, and to abide by them, at all times during the event. I understand the board of directors of Puppies in the Mountains, Inc., at their sole discretion, the right to require me to leave the event at any time without refund of monies paid to participate in the event.</li>
	<li>I understand there is a risk of contracting COVID-19 or any other illness or disease as a result of my activities in traveling to, attending, or returning home from PITM/LFCRC. I agree to release PITM/LFCRC from any liability or responsibility if I contract an illness as a result of my participation.</li>
	<li>I understand that the use or possession of illegal drugs or controlled substances at the event is prohibited. I further understand that the use or possession of firearms (both real and realistic replicas) at the event is prohibited. I also understand that the act of offering, soliciting or agreeing to an act of prostitution, which for this event is defined as an exchange of money for sexual gratification, is strictly forbidden at the event. Violation of any of the aforementioned will result in my immediate expulsion from the event, without reimbursement, and with the possibility of civil and/or criminal complaints being charged against me.</li>
	<li>I am an adult at least 18 years of age as of October 27, 2023, and I understand that I must present a valid government issued photo ID showing my legal name and birth date and sign this release in order to gain admittance to the event. I further affirm that the photo identification I present is an accurate representation of my identity. I further state that all the activities I either participate in or witness at the event shall remain confidential. I understand that participants at the event are acting as consenting adults in a private situation, conforming to the event rules and guidelines that are posted throughout the event. I also understand and agree that by entering the event, I am knowledgeable that the event may include full or partial nudity by some of the participants, as well as acts of a sexual nature. These sights will not offend me.</li>
	<li>I agree to the photography rules, and agree to not take photos of anyone wearing a designated no-photos wristband, and will not post photos that show groups or the venue on social media ever (including after the event).</li>
	<li>I understand that, while PITM takes effort to protect the data that it collects, data breaches may occur due to malicious penetration of computer systems, servers, or third party vendors or by theft of or tampering with physical records. I release all liability and hold PITM harmless for any known or unknown theft of data from its systems or the systems of its third party vendors, for any reason.</li>
	<li>I am not acting in the capacity of, as a member of, or under the direction of, any law enforcement or postal agency or governmental agency of any type, whether federal, state or local in nature. I am not attending the event or any of the activities at the event for the purpose of entrapment or to gather information and/or testimony that would lead to (or further the arrest or prosecution of) the owners and organizers of the event, the owners of the premises, or any individual attending the event. I am not attending the event or any of the activities at the event for the purpose of attempting to influence any organization or person to not do business with PITM or LFCRC, to harass or discourage any person from attending the event or for the purpose of causing harm of any kind to the event and/or its attendees. Furthermore, I am not acting in the capacity of, as a member of, or under the direction of, any media agency, and I will not publish or submit for any kind of publication information, writings, photographs, audio recordings, video recordings, or any other records of activities regarding the event or activities at the event, without the express approval of PITM’s board of directors.</li>
	<li>I agree that if any portion of this release is found to be void or unenforceable, the remaining portion shall remain in full force and effect. I understand that I may not add to, change or delete any of the provisions of this release.</li>
	<li>By signing this release, I acknowledge that if anyone is hurt or property is damaged during my participation in any activity at the event, I may be found by a court of law to have waived my right to maintain a lawsuit against the event on the basis of any claim I have released herein.</li>
</ol>
		<h4>Agreement </h4>
<p>I have had sufficient opportunity to read this entire document. I agree to be bound by the terms here, by additional official instructions or signage, and by the venue rules. I acknowledge that violating the rules may result in termination of my membership and ejection from the event with no refund of monies paid to or in relation to the event. I agree the PITM Board of Directors and ultimately the President of the Board, are the final decision makers regarding any violation or problem. </p>


									</Form.Row>
								</Col>
								</Row>
								<Row>
									<Col md={12}>
										
						{isSavingRegistration ?  
						 <SaveSpinnerContainer>
							<Spinner animation="grow" variant="primary" />&nbsp;
							<Spinner animation="grow" variant="secondary" />&nbsp;
							<Spinner animation="grow" variant="success" />&nbsp;
							<Spinner animation="grow" variant="danger" />&nbsp;
							<Spinner animation="grow" variant="warning" />&nbsp;
							<Spinner animation="grow" variant="info" />
						 </SaveSpinnerContainer>
							:
										<SubmitButton value="I Agree" onClick={e => { saveTermsAgreement(); }}  />
										}
										
										
										
									</Col>
								</Row>
									
							</Card.Body>
						</FormCard>
					</Col>
				</Row>
			</Container>
</>
    );
}
 
export default TermsAndConditions;